<template>
    <div class="content">
        <div class="title">联系我们</div>
        <div class="way">
            <div class="box">
                <div class="sub-title">业务咨询</div>
                <div class="context">
                    <div class="item">
                        <div class="key">客服电话：</div>
                        <div class="value">
                            13701313658
                            <!-- <span>010-63131050</span>
                            <span style="margin-left: 20px;">010-63135206</span><br>
                            <span>010-63135236</span>    -->
                        </div>
                    </div>
                    <div class="item">
                        <div class="key">Email：</div>
                        <div class="value">zhaoziming@huaban2020.com   </div>
                    </div>
                    <div class="item">
                        <div class="key">邮编：</div>
                        <div class="value">100050 </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="sub-title">商务合作</div>
                <div class="context">
                    <div class="item">
                        <div class="key">电话：</div>
                        <div class="value">
                            13701313658 (赵先生)
                        </div>
                    </div>
                    <div class="item">
                        <div class="key">Email：</div>
                        <div class="value">zhaoziming@huaban2020.com</div>
                    </div>
                    <div class="item">
                        <div class="key">邮编：</div>
                        <div class="value">100050 </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="sub-title">手机应用市场</div>
                <div class="context">
                    <div class="item">
                        <div class="key">Email：</div>
                        <div class="value">zhaoziming@huaban2020.com  </div>
                    </div>
                    <div class="item">
                        <div class="key">邮编：</div>
                        <div class="value">100050 </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="position">
            <div class="left">
                <img src="../../assets/img/index/lx-map.png" alt="" class="map">
            </div>
            <div class="right">
                <div class="r-title">
                    <img src="../../assets/img/index/go-left.png" alt="" class="go-left" style="margin-right: 15px;">
                    去华版
                    <img src="../../assets/img/index/go-right.png" alt="" class="go-right" style="margin-left: 15px;">
                </div>
                <div class="right-con">
                    <div class="right-item">
                        <div class="right-name">公交路线</div>
                        <div class="info">北京西步行3公里倒32路公交车至北京站方向和平西路下车，步行500米到华版数字版权服务中心</div>
                    </div>
                    <div class="right-item">
                        <div class="right-name">地铁路线</div>
                        <div class="info">北京西步行3公里倒32路公交车至北京站方向和平西路下车，步行500米到华版数字版权服务中心</div>
                    </div>
                    <div class="right-item">
                        <div class="right-name">公交路线</div>
                        <div class="info">北京西步行3公里倒32路公交车至北京站方向和平西路下车，步行500米到华版数字版权服务中心</div>
                    </div>
                    <div class="right-item">
                        <div class="right-name">地铁路线</div>
                        <div class="info">北京西步行3公里倒32路公交车至北京站方向和平西路下车，步行500米到华版数字版权服务中心</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        
      };
    },
    mounted() {
      
    },
    methods: {
        
    }
  };
</script>

<style scoped lang="scss">
.content{
    width: 1400px;
    margin: 0 auto;
    position:relative;
    .title{
        width: 333px;
        height: 26px;
        background-image: url('../../assets/img/index/uswe.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 26px;
        font-size: 26px;
        font-family: FZLiShu-S01S;
        font-weight: 400;
        color: #333333;
        margin: 40px auto;
    }
    .way{
        
        // background-image: url('../../assets/img/index/lx-bg.png');
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        display: flex;
        flex-wrap: nowrap;
        
        .box{
            width: 429px;
            height: 206px;
            background-image: url('../../assets/img/index/lx-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-right: 56px;
            .sub-title{
                font-size: 16px;
                font-family: FZLiShu-S01;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 45px;
            }
            .context{
                padding: 5px 58px;
                .item{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-bottom: 19px;
                    .key{
                        width: 90px;
                        font-size: 16px;
                        font-family: SimSun;
                        font-weight: 400;
                        color: #333333;
                        text-align: right;
                    }
                    .value{
                        width: 250px;
                        font-size: 16px;
                        font-family: SimSun;
                        font-weight: 400;
                        color: rgba(51, 51, 51, .6);
                    }
                }
            }
        }
        
    }

    .position{
        display: flex;
        flex-wrap: nowrap;
        .left{
            width: 776px;
            height: 665px;
            background-image: url('../../assets/img/index/lx-map-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-left: -100px;
            .map{
                width: 520px;
                height: 370px;
                margin: 140px 0 0 120px;
            }
        }
        .right{
            width: calc(100% - 776px);
            margin-left: 100px;
            .r-title{
                font-size: 22px;
                font-family: FZLiShu-S01S;
                font-weight: 400;
                color: #333333;
                line-height: 19px;
                display: flex;
                justify-content: center;
                margin-top: 126px;
                .go-left,.go-right{
                    width: 18px;
                    height: 19px;
                }
            }
            .right-con{
                .right-item{
                    display: flex;
                    flex-wrap: nowrap;
                    margin-top: 30px;
                    .right-name{
                        width: 126px;
                        height: 26px;
                        font-size: 16px;
                        line-height: 26px;
                        font-family: FZLiShu-S01;
                        font-weight: 400;
                        color: #FFFFFF;
                        background-image: url('../../assets/img/index/go-bg.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        text-align: center;
                    }   
                    .info{
                        margin-left: 10px;
                        font-size: 18px;
                        font-family: SimSun;
                        font-weight: 400;
                        color: #333333;
                        line-height: 30px;
                    }
                }
            }
        }
        
    }
}

</style>
  
  